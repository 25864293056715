#map {
  height: 100%;
  width: 100%;
}
.popup-title {
  font-weight: bold;
  font-size: 16px;
}
.popup-body {
  font-size: 12px;
}
.popup-divider {
  border: none;
  height: 1px;
  background-color: #2eafc9;
}

#data-time-record {
  padding: 2px 20px;
  left: 0;
  bottom: 0;
}
#data-time-record.mobile {
  top: 0;
  bottom: auto !important;
}
#data-total {
  padding: 2px 10px;
  right: 0;
  top: 0;
}
.data-time-and-total {
  position: absolute;
  width: "50%";
  z-index: 999;
  background-color: rgba(255, 255, 255);
  text-align: center;
  font-size: 14px;
}

.toggle-button {
  background-color: #23ae98;
  color: white;
  font-size: 14px;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggle-button-pc {
  width: 35px;
  height: 100px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  left: 0;
  top: calc(50% - 50px);
}

.vertical-text {
  writing-mode: vertical-rl; /* vertical right-to-left */
  text-orientation: upright;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 5px;
}

.toggle-button-mobile {
  width: 110px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.toggle-button-mobile-close {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  bottom: 0px;
}

.toggle-button-mobile-open {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  bottom: -40px;
}
