/* marker-cluster */
.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(208, 16, 39, 0.4);
}
.marker-cluster-large div {
  background-color: rgba(208, 16, 39, 0.7);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
  color: white;
}

/* marker-prefecture */
.marker-prefecture-small {
  background-color: rgba(5, 139, 86, 0.4);
}
.marker-prefecture-small div {
  background-color: rgba(5, 139, 86, 0.7);
}

.marker-prefecture-large {
  background-color: rgba(208, 17, 39, 0.4);
}
.marker-prefecture-large div {
  background-color: rgba(208, 17, 39, 0.7);
}

.marker-prefecture {
  background-clip: padding-box;
  border-radius: 5px;
  height: 27px !important;
  width: 41px !important;
  display: flex;
  place-content: center;
  align-items: center;
}
.marker-prefecture div {
  width: 37px;
  height: 23px;
  text-align: center;
  border-radius: 5px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-prefecture span {
  line-height: 23px;
  color: white;
}
