/* Header */
.header-background {
  background-size: cover;
}
.header-container {
  max-width: 85vw;
  margin: auto;
  padding: 20px;
  padding-bottom: 10px;
}
.header-container.mobile {
  max-width: 100vw;
  padding: 20px 15px;
}

/* Logo */
.logo-container {
  margin-bottom: 20px;
}
.logo-container.mobile {
  margin-bottom: 40px;
}
#ccus-logo {
  max-width: 520px;
  margin: 10px;
}
#ccus-logo.mobile {
  height: 15px;
  margin-left: 0px;
}
#first-logo {
  height: 62px;
}
#first-logo.mobile {
  height: 30px;
}

/* Header First Line font */
.header-line-height {
  line-height: 30px;
}
.header-big-font {
  font-size: 30px;
}
.header-big-font.start {
  letter-spacing: 2px;
  text-wrap: nowrap;
}
.header-big-font.end {
  letter-spacing: -3px;
}
#pic-240k {
  height: 60px;
  margin-right: 30px;
}
.header-special-number {
  font-size: 76px;
  color: #f0ca34;
  text-shadow: #b18539 0px 3px;
  font-weight: bold;
  -webkit-text-stroke: #ffdb4d 1px;
}
.header-special-text {
  color: #f0ca34;
  text-shadow: #b18539 1px 2px;
  font-size: 54px;
  font-weight: bold;
  -webkit-text-stroke: #ffdb4d 1px;
}
.header-first-line-mobile {
  font-size: 30px;
  font-weight: bold;
}

/* Header Second Line Font */
.header-second-line {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.header-second-line.mobile {
  font-size: 17px;
}

/* Header Third Line Font */
.header-btn-pic {
  height: 55px;
}
.header-btn-pic.mobile {
  height: 33px;
}
.header-third-line-mobile {
  flex-direction: column;
}

/* Header Fourth Line Font */
.header-fourth-line {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}
.header-fourth-line.mobile {
  margin-bottom: 16px;
}

/* header-alert-btn */
.header-alter-btn-container {
  padding-bottom: 20px;
  padding-left: 15px;
}
.header-alter-btn-container.mobile {
  padding-bottom: 20px;
  padding-left: 0px;
  margin-top: -15px;
}
.header-alert-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  border: #e93338 1px solid;
  border-radius: 20px;
  color: #e93338;
  /* margin: auto; */
  /* margin-top: 20px; */
}
.header-alert-btn:hover {
  cursor: pointer;
  color: #e93338 !important;
  border: #e93338 1px solid !important;
}
#header-alert-icon {
  height: 15px;
}
.header-alert-btn.mobile {
  margin: auto;
}

/* Modal */
.modal-title {
  font-size: 30px;
  text-align: center;
}
.modal-title.mobile {
  font-size: 25px;
}
.modal-close-icon {
  font-size: 30px;
  color: black;
}
.modal-text {
  font-size: 17px;
  padding-bottom: 15px;
}
.modal-text-mobile {
  font-size: 16px;
}
.modal-wrap .ant-modal-content {
  padding: 50px;
  padding-bottom: 25px;
}
.modal-wrap.mobile .ant-modal-content {
  padding: 25px;
}
