body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* overflow: scroll; */
  font-family: "Noto Sans JP", sans-serif;
}

#root {
  height: 100%;
}

.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.container-mobile {
  position: relative;
  height: 100%;
}

.sidebar {
  position: relative;
  width: 280px;
  height: 100%;
  transition: width 0.3s; /* Define the transition duration */
}
.sidebar-mobile {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 80%;
  left: 0;
}

.content {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  box-sizing: border-box;
  position: relative;
}
.content.mobile {
  height: 80%;
}

.leaflet-control-attribution.leaflet-control {
  text-align: center;
}

#map-cover {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  display: flex;
}
.map-cover-text {
  color: white;
  font-size: 30px;
  text-align: center;
}
