#sidebar {
  box-sizing: border-box;
  height: inherit;
  width: 280px;
  background-color: #dddddd;
  padding: 10px;
}
#sidebar.mobile {
  width: 100%;
}

.side-bar-total {
  text-align: center;
}

.sidebar-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
}

.sidebar-card {
  width: 260px;
  cursor: pointer;
}

.sidebar-card-mobile {
  width: 100%;
  cursor: pointer;
}

.sidebar-card-title {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 5px;
}

.sidebar-card-body {
  font-size: 13px;
}

.sidebar-selected-card {
  background-color: lightgreen;
}
