.footer-background {
  height: 440px;
}
.footer-background.mobile {
  height: 520px;
  background-size: contain;
}
.footer-container {
  max-width: 85vw;
  margin: auto;
}
.footer-first-line {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  margin-top: 0;
  padding-top: 1em;
}
.footer-first-line.mobile {
  font-size: 20px;
}
.footer-company-name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}
.footer-company-name.mobile {
  margin-bottom: revert;
}
.footer-info {
  margin-bottom: 0px;
}
.footer-info.mobile {
  margin-bottom: revert;
}
.footer-company-address {
  margin-top: 0;
  font-size: 16px;
}
.footer-company-homepage {
  margin-top: 0;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.footer-company-homepage:hover {
  cursor: pointer;
}
#new-window-icon {
  height: 10px;
  margin-left: 10px;
}

.footer-company-info-container-mobile {
  margin-top: 30px;
  text-align: center;
}
